<template>
  <a-back-top>
    <UpOutlined
      style="
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 20px;
        background: #fff;
        font-size: 26px;
      "
    />
  </a-back-top>
  <div class="ranking-wrap">
    <div class="top" v-if="!ISHTSY">
      <img src="@/assets/image/ranking_top.png" alt="" />
    </div>
    <div class="top" style="background-color: #6a9fe8" v-else>
      <img src="@/assets/image/ranking_top_blue.png" alt="" />
    </div>
    <div class="sifted">
      <div class="tabs">
        <div
          :class="['item', { active: index + 1 === searchParams.source }]"
          v-for="(item, index) in tabs"
          :key="index"
          @click="switchTabs(index)"
        >
          <CarryOutOutlined style="font-size: 18px; vertical-align: -3px" />
          {{ item.name }}
        </div>
      </div>
      <div class="date">
        <!-- 排行日期 -->
        {{ $t("mine.list_date") }}：<span
          :class="{ active: item.id === searchParams.date }"
          v-for="(item, index) in dateActives"
          :key="index"
          @click="switchDate(item)"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div class="list">
      <div class="mine" v-if="myRanking > 0">
        <div class="item on">
          <div class="icon">
            <img
              v-if="myRanking <= 3"
              :src="require(`@/assets/image/index_ranking_${myRanking}.png`)"
            />
            <div v-else class="num">{{ myRanking }}</div>
          </div>
          <img class="avatar" :src="myItem.portrait" alt="avatar" />
          <div class="user">
            <p class="name">
              <OpenData type="userName" :openid="myItem.realName" />
            </p>
            <p class="department">
              <template
                v-for="(str, strIndex) in myItem.departmentPath.split('/')"
                :key="strIndex"
              >
                <OpenData type="departmentName" :openid="str" />
                <span
                  v-if="strIndex + 1 < myItem.departmentPath.split('/').length"
                  >/</span
                >
              </template>
            </p>
          </div>
          <div class="period">
            <p class="label">
              {{
                rankType == 2 && companyInfo.menu.includes("integral")
                  ? $t("Lab_MyIntegra_T_GradeIntegral")
                  : $t("XB_LearningDuration")
              }}
            </p>
            <!-- 学习时长 -->
            <p class="num">
              {{
                rankType == 2 && companyInfo.menu.includes("integral")
                  ? myItem.totalIntegral
                  : formatTime(myItem.totalTime)
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="totals">
        {{ $t("mine.list_together") }} <span class="blue">{{ Total }}</span>
        {{ $t("mine.people") }}
        <!-- 榜单合计 人 -->
      </div>
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="icon">
          <img
            v-if="index <= 2"
            :src="require(`@/assets/image/index_ranking_${index + 1}.png`)"
          />
          <div v-else class="num">{{ index + 1 }}</div>
        </div>
        <img class="avatar" :src="item.portrait" alt="avatar" />
        <div class="user">
          <p class="name">
            <OpenData type="userName" :openid="item.realName" />
          </p>
          <p class="department">
            <template
              v-for="(str, strIndex) in item.departmentPath.split('/')"
              :key="strIndex"
            >
              <OpenData type="departmentName" :openid="str" />
              <span v-if="strIndex + 1 < item.departmentPath.split('/').length"
                >/</span
              >
            </template>
          </p>
        </div>
        <div class="period">
          <p class="label">
            {{
              rankType == 2 && companyInfo.menu.includes("integral")
                ? $t("Lab_MyIntegra_T_GradeIntegral")
                : $t("XB_LearningDuration")
            }}
          </p>
          <!-- 学习时长 -->
          <p class="num">
            {{
              rankType == 2 && companyInfo.menu.includes("integral")
                ? item.totalIntegral
                : formatTime(item.totalTime)
            }}
          </p>
        </div>
      </div>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '24px',
          height: '32px',
          lineHeight: '32px',
          color: '#BCBCBC',
        }"
      >
        <a-spin v-if="loading" />
        <a-empty v-else-if="dataList.length <= 0" style="padding: 50px 0" />
        <div v-else-if="finished">{{ $t("no_more") }}</div>
        <!-- 没有更多了 -->
        <a-button v-else type="link" @click="loadMore">{{
          $t("mine.see_more_list")
        }}</a-button>
        <!-- 查看更多排行榜 -->
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { homeConfig } from "@/api/user";
import { getRanking, getIntegralRanking } from "@/api/other";
import { formatTime } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const store = useStore();
    const companyInfo = computed(() => store.getters.companyInfo);
    const state = reactive({
      tabs: [
        { name: $t("mine.company_rank") },
        { name: $t("mine.department_rank") },
      ],
      // 公司排行榜 部门排行榜
      dateActives: [
        { id: 1, text: $t("CM_All") }, // 全部
        { id: 2, text: $t("XB_Today") }, // 今日
        { id: 3, text: $t("XB_Week") }, // 本周
        { id: 4, text: $t("XB_Month") }, // 本月
      ],
      dataList: [],
      searchParams: {
        date: 1, // 1全部 2今日 3本周 4本月
        page: 1,
        pageSize: 15,
        source: 1, // 1公司排行 2部门排行
      },
      Total: 0,
      loading: true,
      finished: false,
      userInfo: computed(() => store.getters.userInfo),
      myRanking: 0, //我的排名
      myItem: {},
      rankType: 1,
    });

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.searchParams.page = 1;
      state.myRanking = 0; // 初始化我的排行名次
      state.loading = true;
      state.finished = false;
      getList();
    };

    //上拉加载
    const loadMore = () => {
      state.loading = true;
      getList();
    };

    const getList = () => {
      let action = getRanking;
      if (companyInfo.value.menu.includes("integral") && state.rankType == 2) {
        action = getIntegralRanking;
      }
      action(state.searchParams).then((res) => {
        if (res.ret == 0) {
          state.dataList.push.apply(state.dataList, res.data.ranks);

          // 循环得到我的排行名次
          state.dataList.forEach((item, index) => {
            if (item.userId == state.userInfo.userId) {
              //userId
              state.myRanking = index + 1;
              console.log(state.myRanking);
              state.myItem = item;
            }
          });
          if (state.dataList.length <= 3) {
            state.topThreeList = state.dataList;
          } else {
            state.topThreeList = state.dataList.slice(0, 3);
          }

          //每次拿到数据后页数加1
          state.searchParams.page += 1;
          // 加载状态结束
          state.loading = false;
          state.Total = res.data.totals;
          // 数据全部加载完成
          if (state.dataList.length >= res.data.totals) {
            state.finished = true;
          }
        }
      });
    };

    const switchTabs = (index) => {
      state.searchParams.source = index + 1;
      reloadList();
    };

    const switchDate = (item) => {
      state.searchParams.date = item.id;
      reloadList();
    };

    homeConfig().then((res) => {
      state.loading = true;
      let data = res.data.data || [];
      if (data.length) {
        data.forEach((item) => {
          if (item.moduleType == 6) {
            if (item.rankingSettingJson) {
              let o = JSON.parse(item.rankingSettingJson);
              state.rankType = o.rankType || 1;
            } else {
              state.rankType = 1;
            }
          }
        });
      }
      loadMore();
    });

    return {
      ISHTSY,
      companyInfo,
      ...toRefs(state),
      loadMore,
      switchTabs,
      switchDate,
      formatTime,
    };
  },
};
</script>

<style lang="less" scoped>
.ranking-wrap {
  width: 100%;
  height: 100%;
  background: #eff1f4;
  padding-bottom: 24px;
  .top {
    display: flex;
    justify-content: center;
    min-width: 1200px;
    background-color: #f6bf45;
    // background-image: url("~@/assets/image/ranking_top.png");
    // background-position: center;
    // background-repeat: no-repeat;
    img {
      width: 1200px;
      height: 260px;
      object-fit: cover;
    }
  }
  .sifted {
    background: #fff;
    width: 1200px;
    height: 146px;
    margin: 0 auto;
    border-radius: 6px;
    position: relative;
    top: -70px;
    color: #999999;
    font-size: 14px;
    .tabs {
      height: 70px;
      .item {
        height: 70px;
        line-height: 70px;
        text-align: center;
        width: 50%;
        float: left;
        background: #f8f8f8;
        color: #666666;
        font-size: 16px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        .anticon {
          margin-right: 10px;
        }
        &.active {
          height: 80px;
          line-height: 80px;
          text-align: center;
          position: relative;
          margin-top: -10px;
          background: #ffffff;
          color: #266fff;
        }
      }
    }
    .date {
      height: 76px;
      display: flex;
      align-items: center;
      padding: 0 50px;
      span {
        margin-left: 45px;
        padding: 5px 12px;
        border-radius: 4px;
        cursor: pointer;
        &.active {
          background: #266fff;
          color: #fff;
        }
      }
    }
  }
  .list {
    position: relative;
    top: -40px;
    background: #fff;
    width: 1200px;
    min-height: 500px;
    margin: 0 auto;
    padding: 24px 50px;
    border-radius: 6px;
    .mine {
      height: 130px;
      margin: -26px -70px 10px;
      padding: 0 70px;
      background: url("~@/assets/image/ranking_my_bg.png") no-repeat;
    }
    .totals {
      font-size: 16px;
      margin-bottom: 10px;
      letter-spacing: 0.5px;
      .blue {
        color: #266fff;
      }
    }
    .item {
      height: 174px;
      .mixinFlex(space-between; center);
      border-bottom: 1px solid #f6f6f6;
      &.on {
        height: 112px;
        .icon {
          .num {
            background-color: #fff;
          }
        }
        .avatar {
          width: 74px;
          height: 74px;
        }
        .user {
          .name {
            color: #fff;
            font-size: 16px;
          }
          .department {
            color: #fff;
            font-size: 14px;
          }
        }
        .period {
          .label {
            color: #fff;
          }
          .num {
            color: #fff;
            font-weight: bold;
          }
        }
      }
      .icon {
        width: 30px;
        img {
          widows: 30px;
        }
        .num {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          color: #999;
          background-color: #ededed;
          font-size: 15px;
          .mixinFlex(center; center);
        }
      }
      .avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }
      .user {
        width: calc(100% - 500px);
        line-height: 1.4;
        .name {
          color: #202020;
          font-size: 18px;
          margin: 0;
        }
        .department {
          color: #999;
          font-size: 16px;
          margin: 6px 0 0 0;
        }
      }
      .period {
        width: 250px;
        text-align: right;
        .label {
          color: #999;
          font-size: 14px;
          margin: 0;
        }
        .num {
          color: #ff7300;
          font-size: 22px;
          margin: 0;
          font-weight: bold;
          font-family: DINAlternate-Bold, DINAlternate;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
